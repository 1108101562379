import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useNavigate } from 'react-router-dom';
import Home from './Home';
import Unternehmen from './Unternehmen';
import Leistungen from './Leistungen';
import ContactPage from './ContactPage';
import './App.css';

function App() {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleMenuClick = (path) => {
    setMenuOpen(false); // Menü schließen
    navigate(path); // Zur gewünschten Seite navigieren
  };

  return (
    <div>
      <nav className={`navbar ${menuOpen ? 'menuOpen' : ''}`}>
        {/* Logo als Link zur Startseite */}
        <Link to="/" className="logo">
          Logo
        </Link>

        {/* Burger-Menü für mobile Ansichten */}
        <div className="burgerMenu" onClick={toggleMenu}>
          <div className="burgerBar"></div>
          <div className="burgerBar"></div>
          <div className="burgerBar"></div>
        </div>

        {/* Menü sichtbar in mobilen Ansichten */}
        <ul className="menu">
          <li className="closeMenu" onClick={toggleMenu}>×</li> {/* Schließen-Symbol nur in mobile */}
          <li className="menuItem" onClick={() => handleMenuClick('/')}>Startseite</li>
          <li className="menuItem" onClick={() => handleMenuClick('/leistungen')}>Leistungen</li>
          <li className="menuItem" onClick={() => handleMenuClick('/unternehmen')}>Unternehmen</li>

          {/* Kontakt-Link unten im Menü nur in der mobilen Ansicht */}
          <li className="contactLink" onClick={() => handleMenuClick('/kontakt')}>Kontakt</li>
        </ul>

        {/* Kontakt-Button sichtbar nur in größeren Ansichten */}
        <div className="rightSection">
          <Link to="/kontakt">
            <button className="contactButton">Kontakt</button>
          </Link>
        </div>
      </nav>

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/leistungen" element={<Leistungen />} />
        <Route path="/unternehmen" element={<Unternehmen />} />
        <Route path="/kontakt" element={<ContactPage />} />
      </Routes>
    </div>
  );
}

export default function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}
