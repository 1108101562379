import React from 'react';

function Home() {
  return (
    <div style={styles.container}>
      <h1>Willkommen auf unserer Startseite</h1>
      <p>Dies ist eine Beispiel-Startseite mit wenig Inhalt.</p>
    </div>
  );
}

const styles = {
  container: {
    padding: '20px',
    textAlign: 'center',
  },
};

export default Home;
