import React from 'react';

function Leistungen() {
  return (
    <div style={styles.container}>
      <h1>Unsere Leistungen</h1>
      <p>Hier sind die Leistungen, die wir anbieten.</p>
    </div>
  );
}

const styles = {
  container: {
    padding: '20px',
    textAlign: 'center',
  },
};

export default Leistungen;
